var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('van-search',{staticClass:"searchPlace",attrs:{"clearable":"","placeholder":"请输入流程名字/流程发起人搜索"},on:{"search":_vm.searchdata},model:{value:(_vm.instanceName),callback:function ($$v) {_vm.instanceName=$$v},expression:"instanceName"}}),_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished},on:{"load":_vm.getData},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item,index){return _c('van-cell-group',{key:index,style:({
                            'border-left': '4px solid',
                            'border-radius': '6px',
                            'border-image' : 'linear-gradient(#99BDFA ,#2757DA)5 5'
                        }),attrs:{"url":'/#/todoInfo?id=' +
						item.processInstance.id +
						'&processDefinitionId=' +
						item.processInstance.processDefinitionId +
						'&page=todo'  +
						'&taskId=' +
						item.id +
						_vm.isHasNav},on:{"click":function($event){return _vm.$router.push({
                                path:
                                '/todoInfo?id=' +
								item.processInstance.id +
								'&processDefinitionId=' +
								item.processInstance.processDefinitionId +
								'&page=todo'  +
								'&taskId=' +
								item.id +
								_vm.isHasNav
                            })}}},[_c('van-cell',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title",staticStyle:{"font-weight":"600","font-size":"15px"}},[_c('div',[_vm._v(_vm._s(item.instanceName))])])]},proxy:true}],null,true)}),_c('div',{staticClass:"nodecEll"},[_c('div',{staticClass:"cellTitle"},[_c('span',[_vm._v("发起人：")]),_c('span',[_vm._v(_vm._s(item.processInstance.startUserNickname))])]),_c('div',{staticClass:"cellTitle"},[_c('span',[_vm._v("接收时间：")]),_c('span',[_vm._v(_vm._s(_vm.formatTime(item.createTime)))])])])],1)}):_vm._e(),(_vm.list.length == 0 && _vm.finished)?_c('div',{staticClass:"empty"},[_c('van-empty',{attrs:{"description":"没有内容，空空如也~","image":require('@/assets/img/mag_pic_noinformation.svg')}})],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }