<template>
	<div class="wrap">
		<van-search
            v-model="instanceName"
			clearable
            placeholder="请输入流程名字/流程发起人搜索"
            @search="searchdata"
			class="searchPlace"
        ></van-search>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" @load="getData">
				<template v-if="list.length > 0">
					<van-cell-group v-for="(item, index) in list" :key="index"
					:style="{
                            'border-left': '4px solid',
                            'border-radius': '6px',
                            'border-image' : 'linear-gradient(#99BDFA ,#2757DA)5 5'
                        }"
					@click="
                            $router.push({
                                path:
                                '/todoInfo?id=' +
								item.processInstance.id +
								'&processDefinitionId=' +
								item.processInstance.processDefinitionId +
								'&page=todo'  +
								'&taskId=' +
								item.id +
								isHasNav
                            })
                        "
					:url="
						'/#/todoInfo?id=' +
						item.processInstance.id +
						'&processDefinitionId=' +
						item.processInstance.processDefinitionId +
						'&page=todo'  +
						'&taskId=' +
						item.id +
						isHasNav
					"
					>
						<van-cell>
							<template #title>
								<div class="title" style="font-weight: 600;font-size: 15px;">
									<!-- <div class="line"></div> -->
									<div>{{ item.instanceName }}</div>
								</div>
							</template>
						</van-cell>

						<div class="nodecEll">
                            <div class="cellTitle">
                                <span>发起人：</span>
                                <span>{{item.processInstance.startUserNickname}}</span>
                            </div>
                            <div class="cellTitle">
                                <span>接收时间：</span>
                                <span>{{formatTime(item.createTime)}}</span>
                            </div>
                        </div>

					</van-cell-group>
				</template>

				<div class="empty" v-if="list.length == 0 && finished">
					<van-empty description="没有内容，空空如也~"
						:image="require('@/assets/img/mag_pic_noinformation.svg')"></van-empty>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		formatTime
	} from "@/utils/DateUtil";
	import {
		getTodoFlowList,
	} from "@/api/flow";

	export default {
		data() {
			return {
				instanceName:"",
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				size: 10,
				list: [],
			};
		},
		components: {},
		watch: {},
		created() {},
		methods: {
			searchdata() {
				this.list = [];
                this.onRefresh();
                // this.page = 1;
                // this.list = [];
                // this.getData();
            },
			getData() {
				try {
					this.id = null;
					this.reason = "";
					let params = {
						pageNo: this.page,
						pageSize: this.size,
					};
					if(this.instanceName){
						params.keyword = this.instanceName;
					}
					getTodoFlowList(params).then((res) => {
						if (res.status == 200) {
							if (this.refreshing) {
								this.list = [];
								this.refreshing = false;
							}
							if (res.data && res.data.list.length > 0) {
								this.list = this.list.concat(res.data.list);
								this.page += 1;
							}
							this.loading = false;
							if (this.list.length >= res.data.total) {
								this.finished = true;
							}
						}
					});
				} catch (e) {
					this.loading = false;
					this.finished = true;
				}
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				this.loading = true;
				this.page = 1;
				// 重新加载数据
				this.getData();
			},
			formatTime,
		},
		computed: {
			// 从哪个页面跳转过来的
			isHasNav() {
				return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
			},
		},
	};
</script>

<style lang="less" scoped>
 	/deep/.van-cell{
        display:flex;
        align-items:center;
        padding:8px;
    }
    /deep/.van-cell::after{
        right: 8px;
        left: 8px
    }
	.wrap {
		padding: 0 10px;

		.nodecEll{
            display:flex;
            .cellTitle{
                padding:8px;
                font-size: 12px;
                }
           
        }
		.searchPlace{
			/deep/.van-cell__value{
				display: flex;
				align-items: center;
			}
			/deep/.van-field__body{
                width:100%
            }
			
		}

		.title {
			display: flex;
			align-items: center;

			.line {
				width: 0.2rem;
				height: 0.8rem;
				background: #037ae7;
				margin-right: 10px;
			}
		}

		/deep/ .blue .van-cell__value {
			color: #037ae7;
		}

		/deep/ .red .van-cell__value {
			color: rgb(255, 77, 79);
		}

		/deep/ .van-cell-group {
			margin: 12px 0;
		}
	}
</style>